import styled from "@emotion/styled";
import { Link, graphql } from "gatsby";
import get from "lodash/get";
import { Date, RichText } from "prismic-reactjs";
import React from "react";
import tw from "tailwind.macro";

export const query = graphql`
  {
    prismic {
      allBlog_posts(sortBy: meta_firstPublicationDate_DESC) {
        edges {
          node {
            title
            author
            excerpt
            _linkType
            _meta {
              uid
              firstPublicationDate
              lastPublicationDate
            }
          }
        }
      }
    }
  }
`;

const BlogsPage = ({ data }) => {
  const posts = data.prismic.allBlog_posts.edges.map(edge => edge.node);
  return (
    <Container>
      <List>
        {posts.map(post => {
          const slug = get(post, "_meta.uid");
          const title = get(post, "title", []);
          const excerpt = get(post, "excerpt", []);
          const author = get(post, "author");
          const publishedAt = get(post, "_meta.firstPublicationDate");

          return (
            <Article key={slug}>
              <Snippet to={`/blogs/${slug}`}>
                <Title>{RichText.asText(title)}</Title>
                <Excerpt>{RichText.asText(excerpt)}</Excerpt>
              </Snippet>
              <Meta>
                <Author>{`by ${author} | ${
                  publishedAt ? Date(publishedAt).toLocaleDateString() : ""
                }`}</Author>
                <Link to={`/blogs/${slug}`}>Read More ...</Link>
              </Meta>
            </Article>
          );
        })}
      </List>
    </Container>
  );
};

export default BlogsPage;

const Container = styled.div`
  ${tw`max-w-screen-md mx-auto mt-32 min-h-auto`}
`;

const List = styled.ul`
  ${tw`w-full`}
`;

const Article = styled.li`
  ${tw`list-none mb-4`}
`;

const Title = styled.h2``;

const Excerpt = styled.div`
  font-size: 1.25em;
  filter: brightness(0.75);
`;

const Author = styled.div`
  font-size: 1.25em;
`;

const Snippet = styled(Link)`
  &:hover {
    text-decoration: underline !important;
  }
`;

const Meta = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
